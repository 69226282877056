import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchData = ({ teamId }) => {
  const [loading, setLoading] = useState(false);
  const [test, setTest] = useState({});

  async function fetchDataList() {
    setLoading(true);
    const res = await axios.get(
      process.env.REACT_APP_API_DOMAIN + `/api/users/tests/${teamId}`,
    );

    setLoading(false);  
    if (res.data.status === 1) {
        setTest (res.data.results.test);
    } else {
        setTest ({});
    }
  }

  useEffect(() => {
    fetchDataList();
  }, [teamId]);

  return {
    loading,
    test
  };
};

export default useFetchData;
