import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Table, Tooltip } from 'antd';
import ReactAudioPlayer from 'react-audio-player';
import { getAudioForUser, 
  setPointForAudio, 
  getIndexAudio, 
  decreaseIndexAudio, 
  increaseIndexAudio, 
  setAudios, 
  setIndexAudio, 
  setMaxIndexAudio, 
  getTestById, 
} from '../../../../actions/user';
import { connect } from 'react-redux';
import { Radio, Result, Button, Spin } from 'antd';
import SimilarTestStyle from './index.style';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { SIMILARITY } from '../../../../constants';

const similarOptions = SIMILARITY
  .map ((option) => {
  return {
    id: option.key,
    point: option.key,
    text: `${option.value} (${option.key} điểm)`,
  } 
})

const radioStyle = { 
  display: 'block', 
  height: '30px', 
  lineHeight: '30px', 
  fontSize: '20px'
};
  
const referenceSimilarTestNameRegex = /#RS/;

const SimilarTest = ({ 
  test,
  getAudioForUser,
  setPointForAudio,
  audios,
  user,
  match,
  history,
  indexAudio,
  increaseIndexAudio,
  decreaseIndexAudio,
  getIndexAudio,
  setAudios,
  setIndexAudio,
  setMaxIndexAudio,
  getTestById,
}) => { 
  const [point, setPoint] = useState(0);
  const [disableButtonBack, setDisableButtonBack] = useState(false);
  const [disableButtonNext, setDisableButtonNext] = useState(false);
  const [displaySpinner, setDisplaySpinner] = useState(false);
  const [displayFinishButton, setDisplayFinishButton] = useState(false);
  const [displayFinishForm, setDisplayFinishForm] = useState(false);
  const [pageCurrent, setPageCurrent] = useState();
  const [options, setOptions] = useState([]);
  const [listens, setListens] = useState(0);
  const [disableInput, setDisableInput] = useState(true);
  const [disableSelect, setDisableSelect] = useState(true);
  const [testAudioListened, setTestAudioListened] = useState(false);
  const [originAudioListened, setOriginAudioListened] = useState(false);
  const audio1Ref = useRef();
  const audio2Ref = useRef();

  const isReferenceSimilarTest = referenceSimilarTestNameRegex.test(test?.name);

  useEffect(() => { 
    getTestById(match.params.id);
  }, [getTestById, match.params.id]);
  
  useEffect(() => { 
    if (user) { 
      if (audios && audios.length !== 0 && indexAudio < audios.length && audios[indexAudio]) { 
        getListens(audios[indexAudio]._id, user._id).then(data => { 
          setListens(data);
        });
        setDisplaySpinner(false);
        setPoint(audios[indexAudio].user.point);
        if (audios.every(audio => audio.user.point !== null)) {
          setDisplayFinishButton(true);
        } 
        setPageCurrent(parseInt(indexAudio / 10, 10) + 1);
      } else if (audios && audios.length !== 0  && indexAudio === audios.length ) { 
        if (displayFinishForm === false) { 
          setIndexAudio(indexAudio - 1);
        }   
      } else if ( audios && audios.length !== 0 && indexAudio === audios.length + 1 ) {
        setDisplayFinishForm(true);
      } else { 
        setDisplaySpinner(true);
        getIndexAudio(user._id, match.params.id);
        getAudioForUser(user._id, match.params.id);
        setTimeout(() => { 
          setDisplaySpinner(false);
        }, 500);
      } 
    } 
    setOptions(similarOptions);
  },[
    displaySpinner, 
    getAudioForUser,
    getIndexAudio,
    match.params.id,
    user,
    audios,
    setIndexAudio,
    displayFinishForm,
    test,
    indexAudio
  ]);

  useEffect(() => { 
    if (listens >= 1) { 
      setDisableInput(false);
      setDisableSelect(false);
    } else { 
      setDisableInput(true);
      setDisableSelect(true);
    }
  }, [listens]);

  const onClickFinishButton = () => { 
    setMaxIndexAudio(user._id, match.params.id);
    setDisplayFinishForm(true);
  };
  
  const onChange = e => { 
    setPoint(e.target.value);
  };
  
  const backSentence = async () => { 
    const listens = await getListens(audios[indexAudio - 1]._id, user._id);
    setListens(listens);
    if (indexAudio > 0) {
      decreaseIndexAudio();
      setDisableButtonNext(false);
      setPoint(audios[indexAudio - 1].user.point);
    } else { 
      setDisableButtonBack(true);
    } 
  };
  
  const nextSentence = async () => {
    if (indexAudio < audios.length) { 
      setPointForAudio ({ 
        point,
        indexAudio,
        testId: match.params.id, 
        audioId: audios[indexAudio]._id, 
        userId: user._id 
      });
      if (audios[indexAudio + 1] && audios[indexAudio + 1].user.point) { 
        setPoint(audios[indexAudio + 1].user.point);
      }
      setPoint(0);
      setAudios({ id: audios[indexAudio]._id, point });
    } 

    if (indexAudio < audios.length - 1) {
      const listens = await getListens(audios[indexAudio + 1]._id, user._id);
      setListens(listens);
      setPoint(audios[indexAudio + 1].user.point);
      setDisableButtonBack(false);
    } else { 
      setDisableButtonNext(true);
    } 
  };

  const jumpToSentence = async _id => {
    const listens = await getListens(_id, user._id);
    setListens(listens);
    const index = audios.findIndex(item => item._id === _id);
    setIndexAudio(index);
    if (index !== indexAudio && audios[indexAudio - 1]) {
      setPoint(audios[indexAudio - 1].user.point);
    } 
    
    if (indexAudio < audios.length) { 
      setDisableButtonNext(false);
    } 
  };

  const columnsPoint = [
    { 
      title: 'Câu', 
      dataIndex: '_id', 
      width: 100, 
      render: _id => { 
        const index = audios.findIndex(item => item._id === _id);
        return ( 
          <> 
            {index === indexAudio 
            ? ( <span className="STT-highlight" onClick={() => jumpToSentence(_id)} > Câu {index + 1} </span> ) 
            : ( <span className="STT" onClick={() => jumpToSentence(_id)}> Câu {index + 1} </span> )} </> );
      },
    }, 
    { 
      title: 'Đánh giá',
      dataIndex: 'user', 
      width: 80, 
      align: 'center', 
      render: user => { 
        return (<span>{
          (user.point === null) 
            ? ''
            : SIMILARITY.find(similarity => similarity.key === user.point).value
        }</span>)   
      }, 
    }, 
  ];

  const onClickPageHandler = page => { 
    setPageCurrent(page * 1);
  };

  const increaseListens = async (audioId, userId) => { 
    const body = { audioId, userId, };
    const config = { 
      headers: { 
        'Content-Type': 'application/json'
      }, 
    };
    const res = await Axios.post( 
      `${process.env.REACT_APP_API_DOMAIN}/api/users/increase-listens-audio`, 
      body, 
      config 
    );
    return res.data.results.listens;
  };

  const getListens = async (audioId, userId) => { 
    const res = await Axios.get( `${process.env.REACT_APP_API_DOMAIN}/api/users/get-listens-audio?userId=${userId}&&audioId=${audioId}`);
    return res.data.results.listens;
  };
  
  const onTestAudioEndedHandler = async () => { 
    setTestAudioListened(true);
    if (originAudioListened) {
      const listens = await increaseListens(audios[indexAudio]?._id, user?._id);
      setListens(listens);
    }
  };
  
  const onOriginAudioEndedHandler = async () => { 
    setOriginAudioListened(true);
    if (testAudioListened) {
      const listens = await increaseListens(audios[indexAudio]?._id, user?._id);
      setListens(listens);
    };
  };
  
  useEffect(() => {
    setOriginAudioListened(false);
    setTestAudioListened(false);
  }, [indexAudio])

  return ( 
    <> 
      {displaySpinner ? ( <Spin /> ) : ( 
        <> 
          {!displayFinishForm && audios && audios.length !== 0 && audios[indexAudio] && indexAudio < audios.length && ( 
            <SimilarTestStyle> 
              <div className="table"> 
                <Table 
                  columns={columnsPoint} 
                  dataSource={audios} 
                  rowKey="_id" 
                  bordered 
                  size="middle" 
                  pagination={{ 
                    pageSize: 10, 
                    current: pageCurrent, 
                    onChange: page => onClickPageHandler(page), 
                  }} 
                /> 
              </div> 
              <div className="content-evaluate"> 
                <div key={audios[indexAudio]._id} className="container"> 
                  <div className="user-evaluate"> 
                    <h5> Bạn đang ở câu {' '} {indexAudio + 1}/{audios.length}. Lượt nghe: {listens} </h5> 
                  </div> 
                  <div className="audio-list">
                    <ReactAudioPlayer 
                      controls 
                      autoPlay={audios[indexAudio].user.point ? false : true} 
                      listenInterval={1000} 
                      onEnded={onTestAudioEndedHandler} 
                      ref={audio1Ref} 
                    >
                      <source src={ process.env.REACT_APP_API_DOMAIN + audios[indexAudio].link } /> 
                      <track kind="captions" /> 
                    </ReactAudioPlayer> 
                    <ReactAudioPlayer 
                      controls 
                      autoPlay={false} 
                      listenInterval={1000} 
                      onEnded={onOriginAudioEndedHandler}
                      ref={audio2Ref} 
                    >
                      <source src={ process.env.REACT_APP_API_DOMAIN + audios[indexAudio].originLink } /> 
                      <track kind="captions" /> 
                    </ReactAudioPlayer> 
                  </div>
                 
                </div>
              <div className="evaluate"> 
                {isReferenceSimilarTest ? (
                    <h3>
                      Bạn hãy đánh giá mức độ giống nhau về phong cách nói và
                      cảm xúc của hai đoạn tiếng nói trên
                    </h3>
                  ) : (
                    <h3>
                      Bạn hãy đánh giá hai giọng nói trên
                       có phải cùng một người hay không
                    </h3>
                )}
                <Radio.Group 
                  onChange={onChange} 
                  value={point} 
                  disabled={disableSelect} 
                > 
                  {
                    options.map(option => ( 
                      <Radio 
                        style={radioStyle} 
                        value={option.point}
                      > 
                        <Tooltip 
                          title={option.tooltip} 
                          placement="topLeft"
                        > 
                          {option.text} 
                        </Tooltip> 
                      </Radio> 
                      )
                    )
                  } 
                </Radio.Group> 
              </div> 
                <div className="group-button"> 
                  <Button 
                    disabled={disableButtonBack} 
                    onClick={backSentence} 
                    type="primary" 
                    style={{ backgroundColor: '#0b6398' }} 
                  > 
                    <LeftOutlined /> 
                    Câu trước 
                  </Button> 
                  <Button 
                    disabled={!displayFinishButton} 
                    className="btn btn-warning" 
                    onClick={onClickFinishButton} 
                  > 
                    Kết thúc 
                  </Button> 
                  <Button 
                    disabled={disableButtonNext} 
                    onClick={nextSentence} 
                    type="primary" 
                    style={{ backgroundColor: '#0b6398' 
                  }} 
                  > 
                    Câu tiếp 
                    <RightOutlined type="right" /> 
                  </Button> 
                </div> 
              </div> 
            </SimilarTestStyle> 
            )
          } 
              
          {displayFinishForm && ( 
            <Result 
              status="success" 
              title="Bạn đã hoàn thành bài test" 
              subTitle="Cảm ơn bạn đã tham gia đánh giá chất lượng giọng nói cùng chúng tôi!" 
              extra={[ 
                <Button 
                  style={{ margin: '0 auto' }} 
                  onClick={() => history.push('/')} 
                  type="primary" 
                > 
                  Quay về trang chủ 
                </Button>, 
              ]} 
            /> 
          )} 
        </>
      )} 
    </> 
  );
};
    
  
const mapStateToProps = state => { 
  return { 
    test: state.user.testCurrently, 
    audios: state.user.audios, 
    user: state.auth.user, 
    indexAudio: state.user.indexAudio
  };
};
export default withRouter( 
  connect( 
    mapStateToProps, 
    { 
      getAudioForUser, 
      setPointForAudio, 
      getIndexAudio,
      decreaseIndexAudio,
      increaseIndexAudio,
      setAudios,
      setIndexAudio,
      setMaxIndexAudio,
      getTestById,
    },
  )(SimilarTest),
);
