export const TEST_TYPE = {
    MOS: '1',
    INTENT: '2',
    TRANSCRIPTION: '3',
    EMOTION: '4',
    SIMILAR: '5',
};

export const EMOTIONS = [
    {key: 0, value: 'Lỗi (Không thuộc sắc thái nào kể trên / nhiễu lớn / nhiều người nói)'},
    {key: 1, value: 'Bình thường (Neutral)'},
    {key: 2, value: 'Tức giận (Angry)'},
    {key: 3, value: 'Buồn bã (Sad)'},
    {key: 4, value: 'Vui vẻ (Happy)'}
]

export const SIMILARITY = [
    {key: 1, value: 'Hoàn toàn không giống'},
    {key: 2, value: 'Hơi giống nhau'},
    {key: 3, value: 'Khá giống nhau'},
    {key: 4, value: 'Giống hoàn toàn'},
]

