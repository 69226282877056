import React from 'react';
import Evaluate from './Evaluate';
import EmotionTest from './EmotionTest';
import SimilarTest from './SimilarTest';
import useFetchData from './useFetchData';
import { TEST_TYPE } from '../../../constants';

const Test = ({ match }) => {
    const { test } = useFetchData({ teamId: match.params.id });
    
    const selectTest = () => {
      switch (test.type) {
        case TEST_TYPE.EMOTION: {
          return (<EmotionTest/>);
        }
        case TEST_TYPE.SIMILAR: {
          return (<SimilarTest/>);
        }
        default: {
          return (<Evaluate/>);
        }
      }   
    }

    return (
      <> { selectTest() } </>
    )
}

export default Test;
